.sberpayBtn.sberpayBtn  {
    font: inherit;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    border-radius: 8px;
    outline: none;
    transform: translateZ(0);

    position: relative;
    color: #fff;
    background-image: linear-gradient(92.52deg, #F2EA00 -5.72%, #03D31D 33.01%, #0FB6FE 104.94%);
    transition: all .5s ease;

    justify-content: flex-start;
    height: 56px;
    padding: 0 24px;
}

.tpayBtn.tpayBtn  {
    font: inherit;
    /*background-color: transparent;*/
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    border-radius: 8px;
    outline: none;
    transform: translateZ(0);

    position: relative;
    color: #fff;
    /*background-image: linear-gradient(92.52deg, #F2EA00 -5.72%, #03D31D 33.01%, #0FB6FE 104.94%);*/
    transition: all .5s ease;

    background: #fedd2c;
    background-image: linear-gradient(92.52deg, #F2EA00 -5.72%, #fedd2c 33.01%, #F2EA00 104.94%);

    justify-content: center;
    height: 56px;
    padding: 0 24px;
}

.qrCodeWrapperDefault {
    background: #FFF;
    padding: 16px;
    border-radius: 16px;
    margin-bottom: 36px;
}

.sberpayBtn.sberpayBtn:disabled {
    opacity: .7;
    background-image: linear-gradient(#ccc 0%, #ccc 100%);
}

.tpayBtn.tpayBtn:disabled {
    opacity: .7;
    background-image: linear-gradient(#ccc 0%, #ccc 100%);
}
