.card {
  position: relative;
  background: linear-gradient(180deg, #6285C4 0%, #132648 100%);
  border-radius: 24px;
  width: 384px;
  height: 256px;
}

.cardContent {
  position: absolute;
  box-sizing: border-box;
  height: calc(100% - 4px);
  width: calc(100% - 4px);
  background: linear-gradient(180deg, #1C50AA 0%, #1C376A 100%);
  border-radius: 24px;
  margin: 2px;
  padding: 32px 24px 24px 32px;
  display: flex;
  flex-direction: column;
}

.chip {
  width: 64px;
  height: 64px;
  background-position: center center;
  background-repeat: no-repeat;
  flex-shrink: 0;
  background-size: contain;
}

.nfc {
  width: 48px;
  height: 64px;
  background-position: center center;
  background-repeat: no-repeat;
  flex-shrink: 0;
  background-size: cover;
}

.flag {
  width: 48px;
  height: 32px;
  margin-right: 16px;
  background-position: center center;
  background-repeat: no-repeat;
  flex-shrink: 0;
  background-size: cover;
  border-radius: 6px;
}

.bankName {
  font-family: 'MontserratBold', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: #EAF8FF;
  flex-grow: 1;
  flex-shrink: 1;
  margin: 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.label {
  font-size: 14px;
  font-weight: 500;
  color: #EAF8FF;
  height: 30px;
  line-height: 30px;
  margin-bottom: 0;
}

.value {
  font-family: 'MontserratBold', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: #EAF8FF;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.copyBtn.copyBtn {
  background: #56C6FF;
  border-radius: 6px;
  padding: 0;
  flex-shrink: 0;
  &:hover {
    background: #3c8bb3;
  }
  &:active {
    background: #a2e0ff;
  }
}

.copyBtnIcon {
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center center;
}

.isMobile {
  &.isMobile.card {
    width: auto;
    height: 176px;
  }

  .cardContent {
    padding: 16px 32px;
  }

  .bankName {
    font-family: 'MontserratBold', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    text-align: center;
    color: #EAF8FF;
  }

  .chip {
    width: 40px;
    height: 32px;
    background-size: contain;
  }

  .nfc {
    width: 30px;
    height: 40px;
    background-size: cover;
  }

  .flag {
    width: 26px;
    height: 20px;
    border-radius: 4px;
  }

  .label {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #EAF8FF;
  }

  .value {
    font-family: 'MontserratBold', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    color: #EAF8FF;
  }
}
