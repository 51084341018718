.carBG {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.header {
  background: rgba(10, 28, 62, 0.6);
  height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.headerUnderline {
  background: red;
}

.footer {
  position: relative;
  z-index: 0;
  height: 180px;
  overflow: hidden;
}

.footerWallet {
  position: absolute;
  height: 400px;
  width: 700px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 0;
  top: -104px;
  left: -90px;
  transform: scaleX(-1);
}

.card {
  width: 828px;
  min-height: 256px;
  background: #0A1C3E;
  padding: 32px;
  border-radius: 56px;
  margin: 32px auto 16px auto;
  z-index: 2;
  display: flex;
  flex-direction: column;
}

.card.qrCode {
  width: 1028px;
}

.qrCodeWrapper {
  background: #FFF;
  padding: 16px;
  border-radius: 16px;
}

.under {
  width: 828px;
  position: relative;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  z-index: 2;
}

.empty {
  visibility: hidden !important;
}

.isMobile {
  .carBG {
    display: none;
  }
  .header {
    background: none;
  }
  .footer {
    height: 100px;
  }
  .footerWallet {
    height: 260px;
    width: 320px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 0;
    left: auto;
    top: -36px;
    right: -6px;
    transform: scaleX(1);
  }
  .card {
    width: auto;
    min-height: auto;
    background: none;
    padding: 0 32px;
    border-radius: 0;
    margin: 0 auto;
    //max-width: 520px;
    flex-grow: 2;
    min-width: calc(100vw - 64px);
  }
  .under {
    width: auto;
    padding: 0 32px;
    min-width: calc(100vw - 64px);
  }
}

.textTitle {
  font-family: 'MontserratBold', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  color: #EAF8FF;
}

.textSubtitle {
  font-family: 'MontserratBold', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: #EAF8FF;
}

.textMedium {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #EAF8FF;
}

.textSmall {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #EAF8FF;
}

.listItem {
  position: relative;
  padding-left: 18px;
}

.listItem:before {
  content: attr(data-number);
  left: 0;
  display: inline-block;
  position: absolute;
}

.center {
  text-align: center;
}

.preloading {
  opacity: 0;
  will-change: opacity;
  transition: opacity .3s ease;
  &.ready {
    opacity: 1;
  }
}

